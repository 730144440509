// Generated by Framer (4b166d5)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, RichText, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";

const cycleOrder = ["WQLkyLRf1", "T6Fb7eC7x", "ji_1fveOZ"];

const breakpoints = {"WQLkyLRf1": "(min-width: 1200px)", "T6Fb7eC7x": "(min-width: 810px) and (max-width: 1199px)", "ji_1fveOZ": "(max-width: 809px)"}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"WQLkyLRf1": "framer-v-72rtr7", "T6Fb7eC7x": "framer-v-10lj4fx", "ji_1fveOZ": "framer-v-t601of"};

if (isBrowser) {
                    removeHiddenBreakpointLayers("WQLkyLRf1", breakpoints, variantClassNames);
            }

const humanReadableVariantMap = {"Desktop": "WQLkyLRf1", "Tablet": "T6Fb7eC7x", "Phone": "ji_1fveOZ"};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "WQLkyLRf1", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({"T6Fb7eC7x": {"WQLkyLRf1": {"transformTemplate": undefined}}, "ji_1fveOZ": {"WQLkyLRf1": {"transformTemplate": undefined}}}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "WQLkyLRf1", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated className={cx("framer-y8tod")} style={{"display": "contents", "pointerEvents": pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-72rtr7", className)} style={{...style}} name="Desktop"  ref={ref} {...addVariantProps("WQLkyLRf1")}><RichText fonts={["GF;DM Sans-regular", "GF;DM Sans-700"]}  withExternalLayout={true} verticalAlignment="top" center="x" __fromCanvasComponent={true}  className="framer-1f09yjd" transformTemplate={(_, t) => `translateX(-50%) ${t}`} __htmlStructure={"<p style=\"--framer-text-alignment:center;\"><span style=\"--framer-font-family:&quot;DM Sans&quot;, sans-serif; --framer-font-style:normal; --framer-font-weight:400; --font-selector:R0Y7RE0gU2Fucy1yZWd1bGFy; --framer-font-size:36px;\">{{ text-placeholder }}</span></p>"} htmlFromDesign={"<p style=\"--framer-text-alignment:center;\"><span style=\"--framer-font-family:&quot;DM Sans&quot;, sans-serif; --framer-font-style:normal; --framer-font-weight:400; --font-selector:R0Y7RE0gU2Fucy1yZWd1bGFy; --framer-font-size:36px;\">Ni is coming…</span></p><p style=\"--framer-text-alignment:center;\"><br class=\"trailing-break\"></p><p style=\"--framer-text-alignment:left;\"><span style=\"--framer-font-family:&quot;DM Sans&quot;, sans-serif; --framer-font-style:normal; --framer-font-weight:700; --font-selector:R0Y7RE0gU2Fucy03MDA=; --framer-font-size:36px;\">2028</span></p>"} {...addVariantProps("AUMj_bYHt")}/></motion.div>
<div id="overlay"/>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-y8tod [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-y8tod .framer-72rtr7 { position: relative; overflow: hidden; width: 1200px; height: 800px; background-color: #ffffff; }", ".framer-y8tod .framer-1f09yjd { position: absolute; width: auto; height: auto; left: 50%; top: 347px; flex: none; transform: translateX(-50%); white-space: pre; --framer-paragraph-spacing: 0px; }", "@media (min-width: 1200px) { .framer-y8tod .hidden-72rtr7 { display: none !important; } }", "@media (min-width: 810px) and (max-width: 1199px) { .framer-y8tod .hidden-10lj4fx { display: none !important; } .framer-y8tod .framer-72rtr7 { width: 810px; height: 800px; }}", "@media (max-width: 809px) { .framer-y8tod .hidden-t601of { display: none !important; } .framer-y8tod .framer-72rtr7 { width: 390px; height: 800px; }}"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}, "T6Fb7eC7x": {"layout": ["fixed", "fixed"]}, "ji_1fveOZ": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FrameraugiA20Il: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FrameraugiA20Il;

FrameraugiA20Il.displayName = "Home";

FrameraugiA20Il.defaultProps = {"width": 1200, "height": 800};

addFonts(FrameraugiA20Il, [{"url": "https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriOZSCHBeHFl0.ttf", "family": "DM Sans", "style": "normal", "weight": "400", "moduleAsset": {"url": "https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriOZSCHBeHFl0.ttf", "localModuleIdentifier": "local-module:screen/augiA20Il:default"}}, {"url": "https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriASitOB-sClQX6Cg.ttf", "family": "DM Sans", "style": "normal", "weight": "700", "moduleAsset": {"url": "https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriASitOB-sClQX6Cg.ttf", "localModuleIdentifier": "local-module:screen/augiA20Il:default"}}]);